.auto-column {
  width: 9%;
}

.auto-input {
  width: 100%;
}

.toggle {
  border-radius: 0px;
  font-weight: bold;
  border: 1px solid rgb(162, 193, 162);
}
.toggle.active {
  background-color: rgb(162, 193, 162);
  color: white;
}
td {
  font-weight: bold;
}


.locked-user{
  border: 2px solid red!important;
}



.button-glow {
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #4caf50;
    -webkit-box-shadow: 0 0 3px #4caf50;
  }
  50% {
    background-color: #4caf50;
    -webkit-box-shadow: 0 0 15px #4caf50;
  }
  100% {
    background-color: #4caf50;
    -webkit-box-shadow: 0 0 3px #4caf50;
  }
}
@keyframes glowing {
  0% {
    background-color: #4caf50;
    box-shadow: 0 0 3px #4caf50;
  }
  50% {
    background-color: #4caf50;
    box-shadow: 0 0 15px #4caf50;
  }
  100% {
    background-color: #4caf50;
    box-shadow: 0 0 3px #438bca;
  }
}