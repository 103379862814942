.auto-column{
    width: 5%;
    text-align: center;
}

.auto-header{
    width: 5%;
    text-align: center;
}
.auto-input{
    width: 100%;
}

.dn-column{
    width: 5%;
    text-align: center;
}

.dn-header{
    width: 5%;
    text-align: center;
}
.dn-input{
    width: 100%;
}

.ceo-header{
    width: 5%;
    text-align: center;
}