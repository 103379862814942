/* @import "node_modules/bootstrap/css/bootstrap.css"; */

* {
  margin: 5;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 13px;
}
.sidebar {
  position: fixed;
  height: 100vh;
  z-index: 1;
}

.flex {
  display: flex;
}
.content {
  height: 100vh;
  /* width:1000px; */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  overflow: auto;
}

.title {
  margin-left: 55px;
  background-color: #1d1d1d;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 800;
}
.main {
  height: 100%;
  margin-top: 1rem;
  margin-left: 1rem;
  /* opacity: 0.2; */
}
.heading {
  position: absolute;
  top: 20px;
  left: 45%;
  color: #1d1d1d;
}
.choose {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.select {
  width: 20%;
}
.data-entry {
  position: relative;
  width: 100%;
  left: 27%;
}

.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
}


.css-dip3t8{
  background-color:#1d1d1d!important;
  color: #adadad;
}

.menu-close-icon{
  font-size: 36px!important;
  margin-left: 16px!important;
  cursor: pointer!important;
  margin-top: -10px;
}


.menu-open-icon{
  font-size: 55px!important;
  cursor:pointer!important;
}

.menu-open-icon:hover{
  color:white;
}

.menu-close-icon :hover{
  color:white;
}




.css-1l5fxv3{
  background-color: #3a3939!important; 
  z-index: 999999!important;
}
.css-z5rm24{
  background-color: #3a3939!important;
}
.auto-header-top, .dn-header-top{
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 9;
}

.auto-header, .dn-header {
  background: white;
  position: sticky;
  top: 36.5px; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 9;
}